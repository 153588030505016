<template>
  <div class="w-full">
    <router-link
      :to="`/product/${product_data.GoodsID}`"
      class="relative block w-full mb-2 overflow-hidden rounded-md aspect-square"
    >
      <!-- <span
        v-if="product_data.Discount.indexOf('特價') != -1"
        class="absolute sm:top-3 top-2 left-2 sm:left-3 rounded-sm z-10 px-3 sm:px-5 py-[3px] text-xs sm:text-sm font-medium text-white bg-red-500"
      >
        特價
      </span> -->
      <img
        :src="$ImageUrl(show_image)"
        :alt="product_data.Title"
        class="relative z-0 object-contain w-full h-full transition-all duration-300 hover:md:opacity-60 hover:scale-105"
      />
    </router-link>

    <div class="relative transform md:px-3 md:-translate-y-5">
      <h4
        class="inline-block max-w-full text-sm font-bold text-white rounded-md md:px-2 md:text-black md:truncate md:text-base md:bg-basic_gray"
      >
        {{ product_data.Title }}
      </h4>
      <div class="flex items-center justify-start">
        <p
          v-if="product_data.Price != product_data.SellPrice"
          class="mr-2 text-xs font-medium line-through text-zinc-500"
        >
          NT${{ $MoneyFormat(product_data.Price) }}
        </p>
        <p class="text-base font-black text-primary">
          NT${{ $MoneyFormat(product_data.SellPrice) }}
        </p>
      </div>
      <div class="flex items-center justify-start mt-1">
        <span
          v-if="product_data.Discount.indexOf('特價') != -1"
          class="z-10 mr-1 px-3 py-[3px] text-xs font-bold text-black bg-primary rounded-md"
        >
          特價
        </span>
        <span
          v-if="product_data.Discount.indexOf('免運') != -1"
          class="z-10 mr-1 px-3 py-[3px] text-xs font-bold text-black bg-primary rounded-md"
        >
          免運
        </span>
        <span
          v-if="product_data.Discount.indexOf('贈品') != -1"
          class="z-10 px-3 mr-1 py-[3px] text-xs font-bold text-black bg-primary rounded-md"
        >
          贈品
        </span>
        <span
          v-if="product_data.Discount.indexOf('組合價') != -1"
          class="z-10 px-3 py-[3px] text-xs font-bold text-black bg-primary rounded-md"
        >
          組合價
        </span>
      </div>
      <ol class="flex flex-wrap items-center mt-5 -mx-1">
        <li
          v-for="(item, item_index) in image_data"
          :key="`image_${item_index}`"
          @mouseenter="show_image = item.Image"
          class="w-8 h-8 mx-1 transition-all duration-300 md:w-10 md:h-10"
        >
          <img
            :src="$ImageUrl(item.Image)"
            :class="show_image == item.Image ? 'border-secondary' : ''"
            class="object-contain w-full h-full border rounded-sm hover:border-secondary md:rounded-md"
          />
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductCard",
  props: {
    product_data: {
      require: true,
      type: Object,
    },
  },
  data() {
    return {
      show_image: "",
    };
  },
  created() {
    this.show_image = this.product_data.Image;
  },
  watch: {
    product_data() {
      this.show_image = this.product_data.Image;
    },
  },
  computed: {
    image_data() {
      let images = [];
      this.product_data.Picture.forEach((item) => {
        if (item.ColorID != 0 || item.SizeID != 0) {
          images.push(item);
        }
      });
      return images;
    },
  },
};
</script>