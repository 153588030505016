<template>
  <aside
    :class="{
      'translate-x-full': menu_status,
      'top-[102px]': announcement_list.length > 0,
      'top-[70px]': announcement_list.length <= 0,
    }"
    class="fixed bottom-0 z-30 w-full overflow-x-hidden overflow-y-auto transition-all duration-500 transform -left-full bg-primary"
  >
    <div class="relative z-0 flex flex-col w-full h-full">
      <ol class="flex-1 w-full pt-5">
        <li
          class="w-full px-5"
          v-for="(item, item_index) in menu_list"
          :key="`item_${item_index}`"
        >
          <router-link
            @click.native="$emit('menu-action')"
            class="block py-4 text-sm font-bold text-black transition-all duration-300 border-b border-black hover:md:opacity-50"
            :to="item.link"
          >
            <span class="block text-lg font-bold text-black uppercase">{{
              item.sub_title
            }}</span>
            {{ item.title }}
          </router-link>
        </li>
      </ol>
      <div class="px-5 pb-5">
        <router-link
          to="/account/account_edit"
          @click.native="$emit('menu-action')"
          v-if="CheckLogin()"
          class="block w-full py-3 font-medium text-center text-white transition-all duration-300 border rounded-lg bg-primary hover:md:bg-transparent hover:md:text-primary border-primary"
        >
          會員中心
        </router-link>
        <router-link
          to="/account/login"
          @click.native="$emit('menu-action')"
          v-else
          class="block w-full py-3 font-medium text-center text-white transition-all duration-300 bg-black border border-black rounded-lg hover:md:bg-transparent hover:md:text-black"
        >
          登入 / 註冊
        </router-link>
      </div>
    </div>
  </aside>
</template>

<script>
import { getLocalStorage } from "@/common/cookie";
export default {
  name: "MainMenu",
  props: {
    menu_status: {
      require: true,
      type: Boolean,
    },
  },
  data() {
    return {
      menu_list: [
        {
          title: "商品列表",
          sub_title: "Products",
          link: "/collections",
        },
        {
          title: "關於我們",
          sub_title: "About Us",
          link: "/about",
        },
        {
          title: "最新消息",
          sub_title: "News",
          link: "/news",
        },
        {
          title: "經銷列表",
          sub_title: "Dealers",
          link: "/findus",
        },
        {
          title: "客服中心",
          sub_title: "Support",
          link: "/support",
        },
      ],
    };
  },
  methods: {
    CheckLogin() {
      return getLocalStorage("account_token") != null;
    },
  },
  computed: {
    announcement_list() {
      return this.$store.getters.announcement_list;
    },
  },
};
</script>
