import router from "@/router/index";
import store from "@/store/index";
import { post } from "@/common/request";
var test_code = "";
var sku_word = "blackknight";

// Meta Pixel

export function AddPaymentInfo(shopcart, total_price, form_data) {
  let { product_content, product_ids, total_amount } =
    GetShopCartContent(shopcart);
  const event_id = getEventID(10);

  window.fbq(
    "track",
    "AddPaymentInfo",
    {
      content_category: "結帳頁面",
      content_type: "products",
      content_ids: product_ids,
      contents: product_content,
      currency: "TWD",
      value: 0,
    },
    { eventID: event_id }
  );
  APIAddPayment(
    product_content,
    product_ids,
    total_amount,
    total_price,
    form_data,
    event_id
  );
}

export function AddToCart(product, option, amount) {
  const ColorTitle = product.Stock.filter(
    (item) => item.ColorID == option[0]
  )[0].ColorTitle;
  const SizeTitle = product.Stock.filter((item) => item.SizeID == option[1])[0]
    .SizeTitle;
  const Price = product.Stock.filter(
    (item) => item.SizeID == option[1] && item.ColorID == option[0]
  )[0].SellPrice;
  const option_text = ColorTitle + "," + SizeTitle;
  const event_id = getEventID(10);

  window.fbq(
    "track",
    "AddToCart",
    {
      content_ids: [sku_word + "_" + product.GoodsID],
      content_name: product.Title,
      content_type: "product",
      contents: [
        {
          id: sku_word + "_" + product.GoodsID,
          quantity: amount,
          price: Price,
          option: option_text,
        },
      ],
      currency: "TWD",
      value: 0,
    },
    { eventID: event_id }
  );

  APIAddToCart(product, amount, Price, option_text, event_id);
}

export function CompleteRegistration(form) {
  const event_id = getEventID(10);
  window.fbq(
    "track",
    "CompleteRegistration",
    {
      content_name: "註冊會員",
      status: true,
      currency: "TWD",
      value: 0,
    },
    {
      eventID: event_id,
    }
  );
  APICompleteRegistration(event_id, form);
}

export function InitiateCheckout(shopcart, total_price) {
  let { product_content, product_ids, total_amount } =
    GetShopCartContent(shopcart);
  const event_id = getEventID(10);

  window.fbq(
    "track",
    "InitiateCheckout",
    {
      content_category: "結帳頁面",
      content_ids: product_ids,
      contents: product_content,
      num_items: total_amount,
      currency: "TWD",
      value: 0,
    },
    { eventID: event_id }
  );
  APIInitCheckout(
    product_content,
    product_ids,
    total_amount,
    total_price,
    event_id
  );
}

export function Purchase(shopcart, trade_data, total_price, form_data) {
  let { product_content, product_ids, total_amount } =
    GetShopCartContent(shopcart);

  window.fbq(
    "track",
    "Purchase",
    {
      content_ids: product_ids,
      content_name: "建立訂單",
      content_type: "product",
      contents: product_content,
      currency: "TWD",
      num_items: total_amount,
      value: parseInt(total_price),
    },
    { eventID: "purchase." + trade_data.TradeID }
  );

  APIPurchase(
    product_content,
    product_ids,
    total_amount,
    trade_data,
    total_price,
    form_data
  );
}

export function Search(products, search_string) {
  let product_ids = [];
  let product_content = [];
  products.forEach((item) => {
    product_ids.push(sku_word + "_" + item.GoodsID);
    product_content.push({
      id: item.GoodsID,
      quantity: 1,
      price: GetLowPrice(item.Stock),
    });
  });
  const event_id = getEventID(10);

  window.fbq(
    "track",
    "Search",
    {
      content_category: "搜尋商品",
      content_ids: product_ids,
      content_name: "建立訂單",
      content_type: "product",
      contents: product_content,
      currency: "TWD",
      search_string: search_string,
      value: 0,
    },
    { eventID: event_id }
  );
  APISearch(search_string, product_ids, product_content, event_id);
}

export function ViewContent(product) {
  const event_id = getEventID(10);
  const price = GetLowPrice(product.Stock);
  window.fbq(
    "track",
    "ViewContent",
    {
      content_ids: [sku_word + "_" + product.GoodsID],
      content_category: "瀏覽商品-" + product.Title,
      content_name: "瀏覽商品-" + product.Title,
      content_type: "product",
      contents: [
        {
          id: sku_word + "_" + product.GoodsID,
          quantity: 1,
          price: price,
        },
      ],
      currency: "TWD",
      value: 0,
    },
    { eventID: event_id }
  );

  APIViewContent(product, price, event_id);
}

export function Contact() {
  const event_id = getEventID(10);
  window.fbq("track", "Contact", {}, { eventID: event_id });
  APIContact(event_id);
}

// ConvertAPI

function SendAPI(path, form) {
  if (getCookie("_fbp")) {
    form.FBP = getCookie("_fbp");
  }
  if (getCookie("_fbc")) {
    form.FBP = getCookie("_fbc");
  }
  if (test_code != "") {
    form.TestCode = test_code;
  }

  form.FacebookPageID = 864823375294060;

  return post(path, form, false);
}

// 瀏覽頁面
export function APIViewPage() {
  const event_id = getEventID(10);
  let form = {
    URL: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
    FBP: "",
    FBC: "",
    EventID: event_id,
  };
  window.fbq("track", "PageView", {}, { eventID: event_id });
  return SendAPI("/facebook/view", form);
}
// 瀏覽商品
export function APIViewContent(product, price, event_id) {
  let form = {
    URL: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
    FBP: "",
    FBC: "",
    EventID: event_id,
    Product: product,
    Price: price,
    SkuWord: sku_word,
  };
  return SendAPI("/facebook/view_content", form);
}
// 加入購物車
export function APIAddToCart(product, amount, price, option_text, event_id) {
  let form = {
    URL: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
    FBP: "",
    FBC: "",
    Product: product,
    Price: price,
    ProductOptions: {
      id: sku_word + "_" + product.GoodsID,
      quantity: amount,
      item_price: price,
    },
    EventID: event_id,
    SkuWord: sku_word,
  };

  return SendAPI("/facebook/add_cart", form);
}
// 查看經銷據點
export function APIViewDealer() {
  const event_id = getEventID(10);
  let form = {
    URL: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
    FBP: "",
    FBC: "",
    EventID: event_id,
  };
  return SendAPI("/facebook/dealer", form);
}
// 開始結帳
export function APIInitCheckout(
  product_content,
  product_ids,
  total_amount,
  total_price,
  event_id
) {
  let tmp_content = JSON.parse(JSON.stringify(product_content));
  tmp_content.forEach((item, item_index) => {
    tmp_content[item_index].item_price = item.price;
    delete tmp_content[item_index].price;
    delete tmp_content[item_index].option;
  });

  let form = {
    URL: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
    FBP: "",
    FBC: "",
    ProductContent: tmp_content,
    ProductIDList: product_ids,
    Amount: total_amount,
    Price: total_price,
    EventID: event_id,
  };
  return SendAPI("facebook/init_checkout", form);
}
// 送出訂單
export function APIPurchase(
  product_content,
  product_ids,
  total_amount,
  trade_data,
  total_price,
  form_data
) {
  let tmp_content = JSON.parse(JSON.stringify(product_content));
  tmp_content.forEach((item, item_index) => {
    tmp_content[item_index].item_price = item.price;
    delete tmp_content[item_index].price;
    delete tmp_content[item_index].option;
  });
  if (form_data.consignee_area != "" && form_data.consignee_city != "") {
    const zip_code = store.state.zipcode_data.filter(
      (item) =>
        item.Area == form_data.consignee_area &&
        item.City == form_data.consignee_city
    )[0].ZipCode;

    form_data.zip_code = zip_code;
  } else {
    form_data.zip_code = "";
  }

  form_data.payment = store.state.payment_data.filter(
    (item) => item.PaymentID == form_data.pay_way
  )[0].Title;

  let form = {
    URL: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
    FBP: "",
    FBC: "",
    UserData: form_data,
    Price: total_price,
    TradeID: trade_data.TradeID,
    ProductIDList: product_ids,
    ProductContent: tmp_content,
    Amount: total_amount,
  };
  return SendAPI("facebook/purchase", form);
}
// 搜尋商品
export function APISearch(
  search_string,
  product_ids,
  product_content,
  event_id
) {
  let tmp_content = JSON.parse(JSON.stringify(product_content));
  tmp_content.forEach((item, item_index) => {
    tmp_content[item_index].item_price = item.price;
    delete tmp_content[item_index].price;
  });
  let form = {
    URL: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
    FBP: "",
    FBC: "",
    KeyWord: search_string,
    ProductIDList: product_ids,
    ProductContent: tmp_content,
    EventID: event_id,
  };
  return SendAPI("/facebook/search", form);
}
// 註冊會員
export function APICompleteRegistration(event_id, user_form) {
  let form = {
    URL: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
    FBP: "",
    FBC: "",
    EventID: event_id,
    Name: user_form.name,
    Phone: user_form.phone,
    Email: user_form.email,
    Sex: user_form.sex,
    Birthday: user_form.birthday,
  };
  return SendAPI("/facebook/registration", form);
}
// 增加付款資訊
export function APIAddPayment(
  product_content,
  product_ids,
  total_amount,
  total_price,
  form_data,
  event_id
) {
  let tmp_content = JSON.parse(JSON.stringify(product_content));
  tmp_content.forEach((item, item_index) => {
    tmp_content[item_index].item_price = item.price;
    delete tmp_content[item_index].price;
    delete tmp_content[item_index].option;
  });

  let zip_code = "";
  if (form_data.consignee_area != "" && form_data.consignee_city != "") {
    zip_code = store.state.zipcode_data.filter(
      (item) =>
        item.Area == form_data.consignee_area &&
        item.City == form_data.consignee_city
    )[0].ZipCode;
  }

  form_data.zip_code = zip_code;
  form_data.payment = store.state.payment_data.filter(
    (item) => item.PaymentID == form_data.pay_way
  )[0].Title;

  let form = {
    URL: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
    FBP: "",
    FBC: "",
    ProductContent: tmp_content,
    ProductIDList: product_ids,
    Amount: total_amount,
    Price: total_price,
    UserData: form_data,
    EventID: event_id,
  };

  return SendAPI("/facebook/add_payment", form);
}
// 聯絡我們
export function APIContact(event_id) {
  let form = {
    URL: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
    FBP: "",
    FBC: "",
    EventID: event_id,
  };
  return SendAPI("/facebook/contact", form);
}

// 資料轉換

function GetShopCartContent(shopcart) {
  let product_ids = [];
  let product_content = [];
  let total_amount = 0;
  shopcart.forEach((item) => {
    const ColorTitle = item.product_data.Stock.filter(
      (stock) => stock.ColorID == item.active_option[0]
    )[0].ColorTitle;
    const SizeTitle = item.product_data.Stock.filter(
      (stock) => stock.SizeID == item.active_option[1]
    )[0].SizeTitle;
    const Price = item.product_data.Stock.filter(
      (stock) =>
        stock.SizeID == item.active_option[1] &&
        stock.ColorID == item.active_option[0]
    )[0].SellPrice;

    product_ids.push(sku_word + "_" + item.product_data.GoodsID);
    product_content.push({
      id: sku_word + "_" + item.product_data.GoodsID,
      quantity: item.amount,
      price: parseInt(Price),
      option: ColorTitle + "," + SizeTitle,
    });

    total_amount += parseInt(item.amount);
  });

  return {
    total_amount: total_amount,
    product_ids: product_ids,
    product_content: product_content,
  };
}

function GetLowPrice(stocks) {
  let price = 9999;
  stocks.forEach((item) => {
    parseInt(item.SellPrice) < price ? (price = parseInt(item.SellPrice)) : "";
  });
  return price == 9999 ? 0 : price;
}

function getCookie(name) {
  function escape(s) {
    // eslint-disable-next-line no-useless-escape
    return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, "\\$1");
  }
  var match = document.cookie.match(
    RegExp("(?:^|;\\s*)" + escape(name) + "=([^;]*)")
  );
  return match ? match[1] : null;
}

function getEventID(randomLength) {
  return Number(
    Math.random().toString().substr(2, randomLength) + Date.now()
  ).toString(36);
}
