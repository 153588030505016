<template>
  <footer class="relative z-10 w-full border-t border-zinc-800">
    <div class="w-full bg-black border-b border-zinc-800">
      <div
        class="flex items-center justify-between md:px-10 px-5 max-w-[1440px] mx-auto w-full py-4"
      >
        <ol class="flex items-center -mx-3">
          <li class="px-3" v-if="$GetColumn('company_facebook') != ''">
            <a
              :href="$GetColumn('company_facebook')"
              target="_blank"
              class="text-white transition-all duration-300 hover:text-primary"
            >
              <i class="text-xl icon-facebook"></i>
            </a>
          </li>
          <li class="px-3" v-if="$GetColumn('company_instagram') != ''">
            <a
              :href="$GetColumn('company_instagram')"
              target="_blank"
              class="text-white transition-all duration-300 hover:text-primary"
            >
              <i class="text-xl icon-instagram"></i>
            </a>
          </li>
          <li class="px-3" v-if="$GetColumn('company_line') != ''">
            <a
              :href="$GetColumn('company_line')"
              target="_blank"
              class="text-white transition-all duration-300 hover:text-primary"
            >
              <i class="text-xl icon-line"></i>
            </a>
          </li>
        </ol>

        <button
          @click="ScrollToTop"
          class="flex items-center px-4 py-2 font-bold transition-all duration-300 border rounded-md text-primary border-primary hover:text-black hover:bg-primary"
        >
          <i class="mr-5 icon-chevron-up"></i> GO TOP
        </button>
      </div>
    </div>
    <div class="w-full bg-black">
      <div
        class="flex flex-wrap items-center justify-between md:px-10 px-5 max-w-[1440px] mx-auto w-full py-14"
      >
        <div
          class="flex flex-wrap items-stretch order-2 w-full lg:order-1 md:w-auto"
        >
          <div class="w-1/2 mb-10 md:w-auto md:mr-20 md:mb-0">
            <p class="mb-5 text-sm text-zinc-400">網站地圖</p>
            <ol>
              <li
                v-for="(item, item_index) in menu_list"
                :key="`menu_${item_index}`"
                class="mb-3"
              >
                <router-link
                  :to="item.link"
                  class="text-sm font-semibold text-white transition-all duration-300 hover:text-primary"
                >
                  {{ item.title }}
                </router-link>
              </li>
            </ol>
          </div>

          <div class="w-1/2 mb-10 md:w-auto md:mr-20 md:mb-0">
            <p class="mb-5 text-sm text-zinc-400">條款與規範</p>
            <ol>
              <li class="mb-3">
                <router-link
                  to="/terms/terms_of_customer"
                  class="text-sm font-semibold text-white transition-all duration-300 hover:text-primary"
                >
                  服務條款
                </router-link>
              </li>
              <li class="mb-3">
                <router-link
                  to="/terms/site_privacy"
                  class="text-sm font-semibold text-white transition-all duration-300 hover:text-primary"
                >
                  隱私權政策
                </router-link>
              </li>
              <!-- <li class="mb-3">
                <router-link
                  to="/terms/return_policy"
                  class="text-sm font-semibold text-white transition-all duration-300 hover:text-primary"
                >
                  退換貨政策
                </router-link>
              </li> -->
            </ol>
          </div>

          <div class="w-full md:w-auto md:mr-20">
            <p class="mb-5 text-sm text-zinc-400">聯絡資訊</p>
            <ol>
              <li class="flex items-center mb-3">
                <p class="mr-3 text-sm font-semibold text-white">聯絡電話</p>
                <p class="text-sm font-semibold text-zinc-400">
                  {{ $GetColumn("company_phone") }}
                </p>
              </li>
              <li class="flex items-center mb-3">
                <p class="mr-3 text-sm font-semibold text-white">公司地址</p>
                <p class="text-sm font-semibold text-zinc-400">
                  {{ $GetColumn("company_address") }}
                </p>
              </li>
              <li class="flex items-center mb-3">
                <p class="mr-3 text-sm font-semibold text-white">電子信箱</p>
                <p class="text-sm font-semibold text-zinc-400">
                  {{ $GetColumn("company_email") }}
                </p>
              </li>
              <li class="flex items-center mb-3">
                <p class="mr-3 text-sm font-semibold text-white">營業時間</p>
                <p class="text-sm font-semibold text-zinc-400">
                  {{ $GetColumn("company_time") }}
                </p>
              </li>
            </ol>
          </div>
        </div>

        <div
          class="flex justify-center order-1 w-full mb-14 lg:mb-0 lg:justify-end lg:flex-1 lg:order-2 lg:w-auto"
        >
          <router-link to="/">
            <img src="/img/logo@lg.webp" class="w-52 md:w-64" />
          </router-link>
        </div>
      </div>
    </div>
    <div class="w-full bg-black">
      <div
        class="max-w-[1440px] mx-auto w-full py-4 flex items-center flex-wrap justify-center md:justify-end md:px-10 px-5"
      >
        <img class="mr-2 h-7" src="/img/visa.svg" />
        <img class="mr-2 h-7" src="/img/master.svg" />
        <!-- <img class="h-7" src="/img/jcb.svg" /> -->
        <!-- <img class="h-7" src="/img/line.svg" /> -->
      </div>
    </div>
    <div class="w-full bg-black border-t border-zinc-800">
      <div
        class="flex items-center flex-wrap justify-center md:justify-between md:px-10 px-5 max-w-[1440px] mx-auto w-full py-4"
      >
        <p class="text-xs text-white">©2023 {{ $GetColumn("company_name") }}</p>

        <p class="text-xs text-white">
          DESIGN BY
          <a
            class="ml-2 text-primary"
            href="https://www.yongxin-design.com"
            target="_blank"
            >泳欣事業有限公司</a
          >
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
  data() {
    return {
      menu_list: [
        {
          title: "商品列表",
          sub_title: "Products",
          link: "/collections",
        },
        {
          title: "關於我們",
          sub_title: "About Us",
          link: "/about",
        },
        {
          title: "最新消息",
          sub_title: "News",
          link: "/news",
        },
        {
          title: "經銷據點",
          sub_title: "Dealers",
          link: "/findus",
        },
        // {
        //   title: "影音專區",
        //   sub_title: "Videos",
        //   link: "/video",
        // },
        {
          title: "客服中心",
          sub_title: "Support",
          link: "/support",
        },
        {
          title: "非會員訂單查詢",
          sub_title: "Order Search",
          link: "/order/search",
        },
      ],
    };
  },
  methods: {
    ScrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>
