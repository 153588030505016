<template>
  <section data-scroll-section class="relative z-10 w-full pt-20">
    <div
      class="relative w-full px-0 mx-auto max-w-screen-2xl sm:pb-40 2xl:px-0 sm:px-5"
    >
      <div class="relative z-10 flex justify-end pr-5 mb-10 sm:pr-0">
        <img
          width="768"
          height="105"
          class="relative z-10 block object-contain w-11/12 sm:w-3/5"
          src="/img/home/ContactText.webp"
          :alt="$GetColumn('brand_name')"
        />
      </div>
      <div
        class="w-full aspect-[390/488] sm:aspect-[664/830] md:aspect-[1280/512] relative z-10 px-10 sm:px-20 overflow-hidden bg-cover bg-center sm:rounded-xl flex justify-center items-center"
        :style="`background-image:url(${$ImageUrl(
          $GetColumn('contact_footer_image')
        )})`"
      >
        <div
          class="relative z-10 flex flex-wrap items-center justify-between w-full"
        >
          <div class="mb-10 md:mb-0">
            <header class="flex flex-wrap items-end mb-5">
              <h2
                class="mr-2 sm:text-[40px] sm:mb-0 sm:w-auto w-full mb-5 text-4xl md:text-5xl font-black text-white"
              >
                聯絡我們
              </h2>
              <h3 class="px-1 font-black rounded-sm font-anybody bg-primary">
                CONTACT US
              </h3>
            </header>
            <p class="text-basic_gray">
              如有任何問題，歡迎填寫資料，我們將與您聯絡。
            </p>
          </div>
          <router-link
            to="/support"
            class="px-10 py-3 font-bold transition-colors duration-200 border rounded-md hover:bg-primary hover:text-black text-primary border-primary"
            >預約專人服務</router-link
          >
        </div>

        <span
          class="absolute top-0 bottom-0 left-0 right-0 z-0 block bg-black opacity-60"
        ></span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactFooter",
  data() {
    return {
      hover: false,
    };
  },
};
</script>
