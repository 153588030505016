<template>
  <main class="relative w-full">
    <Carousel />
    <div class="w-full px-5 mx-auto max-w-screen-2xl 2xl:px-0">
      <div class="w-full overflow-hidden rounded-xl">
        <img
          :src="$ImageUrl($GetColumn('home_promote_image_pc'))"
          class="hidden w-full md:block"
        />
        <img
          :src="$ImageUrl($GetColumn('home_promote_image_mb'))"
          class="block w-full md:hidden"
        />
      </div>
    </div>
    <ProductList ref="ProductList" />
    <Features ref="Features" />
    <NewsList ref="NewsList" />
  </main>
</template>

<script>
import Carousel from "@/components/home/Carousel.vue";
import ProductList from "@/components/home/ProductList.vue";
import NewsList from "@/components/home/NewsList.vue";
import Features from "@/components/home/Features.vue";

export default {
  name: "HomeView",
  components: {
    Carousel,
    ProductList,
    NewsList,
    Features,
  },
  computed: {
    image_loaded() {
      return this.$store.state.image_loaded;
    },
  },
  watch: {
    image_loaded() {
      if (this.image_loaded) {
        this.$refs.ProductList.SetGsap();
        this.$refs.Features.SetGsap();
        this.$refs.NewsList.SetGsap();
      }
    },
  },
  created() {
    this.$PageInit(
      this,
      this.$GetColumn("meta_title_home"),
      this.$GetColumn("meta_content_home"),
      this.$GetColumn("meta_image_home") != ""
        ? this.$ImageUrl(this.$GetColumn("meta_image_home"))
        : ""
    );
  },
};
</script>
