<template>
  <section
    ref="MainContent"
    data-section
    class="relative z-10 w-full py-0 mb-20"
  >
    <div
      class="relative z-10 w-full px-5 mx-auto max-w-screen-2xl 2xl:px-0 md:px-10"
    >
      <img
        width="427"
        height="97"
        src="/img/home/NewsText.webp"
        class="relative z-10 block w-2/3 mb-6 ml-auto sm:w-1/2 md:w-1/3"
        :alt="$GetColumn('brand_name')"
      />
      <header class="flex items-end mb-20">
        <h2 class="mr-2 overflow-hidden">
          <span
            data-section-title
            class="block sm:text-[40px] text-4xl md:text-5xl font-black text-white"
            >最新消息</span
          >
        </h2>
        <h3 class="overflow-hidden">
          <span
            data-section-subtitle
            class="block px-1 font-black rounded-sm font-anybody bg-primary"
            >NEWS</span
          >
        </h3>
      </header>
      <div data-section-content>
        <ol class="flex flex-wrap items-stretch mb-10 -mx-5">
          <li
            v-for="(item, item_index) in news_data"
            :key="`news_${item_index}`"
            class="w-full px-5 mb-10 lg:w-1/3"
          >
            <div class="flex flex-col w-full h-full">
              <router-link
                :to="`/news/${item.NewsID}`"
                class="block w-full mb-5 overflow-hidden aspect-square rounded-xl"
              >
                <img
                  :src="$ImageUrl(item.Image1)"
                  class="block object-cover w-full h-full transition-all duration-500 transform hover:scale-105"
                />
              </router-link>
              <h4 class="mb-3 text-xl font-bold text-white truncate">
                {{ item.Title }}
              </h4>
              <div class="flex-1 text-sm text-zinc-500">
                {{ GetContent(item.Content) }}
              </div>
            </div>
          </li>
        </ol>

        <div class="w-full text-center">
          <router-link
            to="/news"
            class="inline-block w-full max-w-full px-10 py-3 text-base font-black transition-all duration-300 bg-transparent border rounded-lg text-primary border-primary md:w-80 hover:bg-primary hover:text-black"
            >查看更多文章</router-link
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { section_animation } from "@/gsap/section";
export default {
  name: "NewsListSection",
  data() {
    return {
      section_animation: null,
    };
  },
  computed: {
    news_data() {
      return this.$store.state.news_data.slice(0, 6);
    },
  },
  methods: {
    GetContent(val) {
      var msg = val.replace(/<\/?[^>]*>/g, ""); //去除HTML Tag
      msg = msg.replace(/[|]*\n/, ""); //去除行尾空格
      msg = msg.replace(/&npsp;/gi, ""); //去掉npsp

      return msg.length > 50 ? msg.slice(0, 50) + "..." : msg;
    },
    SetGsap() {
      this.section_animation = new section_animation(this.$refs.MainContent);
    },
  },
};
</script>