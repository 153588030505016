<template>
  <section ref="MainContent" data-section class="relative z-10 w-full">
    <div class="w-full px-5 pb-40 mx-auto max-w-screen-2xl 2xl:px-0 sm:px-10">
      <img
        width="640"
        height="91"
        class="relative z-10 block w-full mb-10 ml-auto sm:w-4/5 md:w-1/2"
        :alt="$GetColumn('brand_name')"
        src="/img/home/FeatureText.webp"
      />
      <header class="flex items-end mb-20">
        <h2 class="mr-2 overflow-hidden">
          <span
            data-section-title
            class="block sm:text-[40px] text-4xl md:text-5xl font-black text-white"
            >產品特色</span
          >
        </h2>
        <h3 class="overflow-hidden">
          <span
            data-section-subtitle
            class="block px-1 font-black rounded-sm font-anybody bg-primary"
          >
            FEATURES
          </span>
        </h3>
      </header>
      <div class="relative z-10 block w-full">
        <ol
          data-section-content
          v-if="$GetColumn('home_feature_title_1') != null"
          class="relative flex flex-wrap md:-mx-5"
        >
          <li
            v-for="item in 4"
            :key="`feature_${item}`"
            class="relative z-10 w-full py-5 border-b md:px-5 md:border-b-0 border-basic_gray border-opacity-20 md:w-1/2"
          >
            <div
              class="flex flex-col-reverse flex-wrap items-start mb-5 sm:items-center sm:justify-end sm:flex-row-reverse sm:flex-nowrap"
            >
              <h3
                class="w-full text-base font-black sm:text-xl text-primary sm:w-auto"
              >
                {{ $GetColumn(`home_feature_title_${item}`) }}
              </h3>
              <span
                class="inline-block px-2 sm:mb-0 mb-2 py-[2px] mr-4 text-sm font-black text-black rounded-md sm:px-1 sm:py-0 sm:text-base font-anybody bg-primary"
              >
                FEATURE 0{{ item }}
              </span>
            </div>
            <p
              v-html="$GetColumn(`home_feature_content_${item}`)"
              class="text-sm sm:text-base text-basic_gray"
            ></p>
          </li>
        </ol>
        <span
          class="absolute top-1/2 md:block hidden left-5 right-5 h-[1px] bg-basic_gray bg-opacity-10 transform -translate-y-1/2"
        ></span>
        <span
          class="absolute top-5 md:block hidden bottom-5 left-1/2 w-[1px] bg-basic_gray bg-opacity-10 transform -translate-x-1/2"
        ></span>
      </div>
    </div>
  </section>
</template>

<script>
import { section_animation } from "@/gsap/section";
export default {
  name: "HomeFeatureSection",
  data() {
    return {
      section_animation: null,
    };
  },
  methods: {
    SetGsap() {
      this.section_animation = new section_animation(this.$refs.MainContent);
    },
  },
  computed: {
    feature_list() {
      return [
        {
          title: this.$GetColumn("home_feature_title_1"),
        },
      ];
    },
  },
};
</script>
