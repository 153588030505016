import { gsap } from "@/gsap/GsapLoader";

export class section_animation {
  constructor(el) {
    this.el = el;
    this.title = el.querySelector("[data-section-title]");
    this.sub_title = el.querySelectorAll("[data-section-subtitle]");
    this.content = el.querySelectorAll("[data-section-content]");
    this.timeline = null;
    this.setup();
  }

  setup() {
    this.timeline != null ? this.timeline.kill() : "";
    this.timeline = gsap.timeline({
      scrollTrigger: {
        // scroller: "#app",
        trigger: this.el,
        start: "top 70%",
        toggleActions: "restart play play reverse",
      },
    });

    this.timeline
      .fromTo(
        this.title,
        {
          y: "110%",
          transformOrigin: "left top",
        },
        {
          y: "0%",
          transformOrigin: "left top",
          duration: 0.6,
        },
        "first"
      )
      .fromTo(
        this.sub_title,
        {
          opacity: 0,
          y: "100%",
        },
        {
          opacity: 1,
          y: "0%",
          delay: 0.3,
        },
        "first"
      )
      .fromTo(
        this.content,
        {
          opacity: 0,
          y: "100px",
        },
        {
          opacity: 1,
          y: "0px",
          delay: 0.3,
        },
        "first"
      );
  }
}
