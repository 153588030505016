<template>
  <section
    ref="MainContent"
    data-section
    class="relative z-10 w-full px-5 py-20 mx-auto max-w-screen-2xl 2xl:px-0 md:px-10"
  >
    <img
      width="1280"
      height="100"
      src="/img/home/MainProductText.webp"
      class="relative z-10 hidden w-full mb-6 md:block"
      :alt="$GetColumn('brand_name')"
    />
    <img
      width="592"
      height="156"
      src="/img/home/MainProductText@sm.webp"
      class="relative z-10 block w-full mb-6 md:hidden"
      :alt="$GetColumn('brand_name')"
    />
    <header class="flex items-end mb-20">
      <h2 class="mr-2 overflow-hidden">
        <span
          data-section-title
          class="block sm:text-[40px] text-4xl md:text-5xl font-black text-white"
        >
          主打商品</span
        >
      </h2>
      <h3 class="overflow-hidden">
        <span
          data-section-subtitle
          class="block px-1 font-black rounded-sm font-anybody bg-primary"
          >MAIN PRODUCTS</span
        >
      </h3>
    </header>

    <div data-section-content>
      <div ref="GlideSlider" class="mb-10 glide">
        <div class="relative glide__track" data-glide-el="track">
          <ul class="flex items-stretch -mx-1 overflow-hidden glide__slides">
            <li
              class="flex-shrink-0 w-1/2 px-1 md:w-1/4 glide__slide"
              v-for="(item, item_index) in product_data"
              :key="`product_${item_index}`"
            >
              <ProductCard :ref="item.Title" :product_data="item" />
            </li>
          </ul>
        </div>
      </div>

      <div class="flex items-center justify-end w-full">
        <button
          @click="Prev"
          class="flex items-center justify-center w-12 h-12 mr-1 transition-all duration-300 bg-transparent border rounded-md text-primary border-primary hover:md:bg-primary hover:md:text-black"
        >
          <i class="block text-xl transform icon-chevron-right -scale-100"></i>
        </button>
        <button
          @click="Next"
          class="flex items-center justify-center w-12 h-12 ml-1 transition-all duration-300 bg-transparent border rounded-md text-primary border-primary hover:md:bg-primary hover:md:text-black"
        >
          <i class="text-xl icon-chevron-right"></i>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import "@glidejs/glide/dist/css/glide.core.min.css";
import Glide from "@glidejs/glide";
import ProductCard from "@/components/productList/ProductCard.vue";
import { getProductListData } from "@/common/data_convert";
import { section_animation } from "@/gsap/section";
export default {
  name: "ProductListSection",
  components: {
    ProductCard,
  },
  props: {
    title: {
      type: String,
    },
  },
  data() {
    return {
      glide_slider: null,
      section_animation: null,
    };
  },
  methods: {
    Next() {
      this.glide_slider.go(">");
    },
    Prev() {
      this.glide_slider.go("<");
    },
    SetGsap() {
      this.section_animation = new section_animation(this.$refs.MainContent);
    },
  },
  computed: {
    product_data() {
      return getProductListData(this.$store.getters.home_promote_products);
    },
  },
  mounted() {
    this.glide_slider = new Glide(this.$refs.GlideSlider, {
      bound: true,
      perView: 4,
      autoplay: 5000,
      breakpoints: {
        1200: {
          perView: 4,
        },
        1024: {
          perView: 3,
        },
        768: {
          perView: 2,
        },
        640: {
          perView: 1,
        },
      },
    });
    this.glide_slider.mount();
  },
};
</script>
